* {
  margin: 0;
  padding: 0;
}
.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.imgBG2::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.position-absolute.top-50.start-50.translate-middle.text-center.titleMain,
.arrowMain img,
.buttonSingCourse {
  position: relative;
  z-index: 2;
}
.carousel-control-prev {
  display: none;
}
.carousel-control-next {
  display: none;
}
.imgBG2 {
  height: 80vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}
.imgBG2 img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.carousel-title {
  font-size: 3.125vw;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 25%;
  color: #fff;
}
.carousel-text {
  font-size: 27px;
  font-weight: 600;
  line-height: 1;
  width: 520px;
  margin-left: 60px;
  color: #fff;
}
.arrowMain {
  margin-left: 100px;
  margin-top: 0;
}
.buttonSingCourse {
  margin-left: 200px;
  width: 200px;
  height: 60px;
  border-radius: 100px;
  background-color: #fff;
  color: black;
  font-weight: 700;
  transition: color .1s;
}
.buttonSingCourse:active:before {
  background: #408eb8;
  transition: background 0s;
}
.btn:hover {
  background-color: #3CA3DC;
}
@media (min-width: 1921px) {
  .carousel-title,
  .carousel-text {
    font-size: calc(1vw + 1vh + 0.5vmin);
  }
  .buttonSingCourse {
    width: calc(10vw + 10vh + 0.5vmin);
    height: calc(5vw + 5vh + 0.2vmin);
    font-size: calc(1vw + 1vh + 0.3vmin);
  }
}

@media (min-width: 1281px) and (max-width: 1367px) {
  .imgBG2 {
    height: 500px;
  }
  .arrowMain {
    margin-top: -80px;
  }
  .buttonSingCourse {
    margin-bottom: 80px;
  }
}

@media (min-width: 1025px) and (max-width: 1281px) {
  .imgBG2 {
    height: 450px;
  }
  .arrowMain {
    margin-top: -80px;
  }
  .buttonSingCourse {
    margin-bottom: 40px;
  }
}
@media (min-width: 767px) and (max-width: 1025px) {
  .imgBG2 {
    height: 300px;
  }
  .carousel-title {
    font-size: 35px;
    line-height: 1.2;
    margin-left: -10px;
    width: 450px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .carousel-text {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    margin-left: 35px;
    text-align: center;
    width: 350px;
  }
  .arrowMain {
    position: relative;
    margin-left: -50px;
  }
  .buttonSingCourse {
    margin-left: 0px;
    margin-bottom: 750px;
  }
}
@media (min-width: 710px) and (max-width: 767px) {
  .imgBG2 {
    height: 500px;
  }
  .carousel-title {
    text-align: center;
    font-size: 35px;
    line-height: 1.2;
    margin-left: 120px;
    width: 450px;
    margin-bottom: 50px;
    margin-top: 850px;
  }
  .carousel-text {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    margin-left: 170px;
    text-align: center;
    width: 350px;
  }
  .arrowMain {
    position: relative;
    margin-left: -100px;
    margin-top: -70px;
  }
  .buttonSingCourse {
    position: relative;
    left: -10%;
    margin-bottom: 1550px;
  }
}
@media (min-width: 650px) and (max-width: 710px) {
  .position-absolute.top-50.start-50 {
    top: -35% !important;
  }
  .imgBG2 {
    height: 500px;
  }
  .carousel-title {
    font-size: 35px;
    line-height: 1.2;
    margin-left: 90px;
    width: 450px;
    margin-bottom: 50px;
    margin-top: 200px;
  }
  .carousel-text {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    margin-left: 138px;
    text-align: center;
    width: 350px;
  }
  .arrowMain {
    position: relative;
    margin-left: -80px;
    margin-top: -40px;
  }
  .buttonSingCourse {
    position: relative;
    left: -6%;
    top: -30%;
    margin-bottom: 30px;
  }
}
@media (min-width: 639px) and (max-width: 650px) {
  .imgBG2 {
    height: 500px;
  }
  .carousel-title {
    font-size: 35px;
    line-height: 1.2;
    margin-left: 90px;
    width: 450px;
    margin-bottom: 50px;
    margin-top: -580px;
  }
  .carousel-text {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    margin-left: 140px;
    text-align: center;
    width: 350px;
  }
  .arrowMain {
    position: relative;
    margin-left: -90px;
    margin-top: -400px;
  }
  .buttonSingCourse {
    position: relative;
    left: -8%;
    margin-bottom: 30px;
  }
}
@media (min-width: 576px) and (max-width: 639px) {
  .position-absolute.top-50.start-50 {
    top: -35% !important;
  }
  .imgBG2 {
    height: 300px;
  }
  .carousel-title {
    font-size: 35px;
    line-height: 1.2;
    margin-left: -10px;
    width: 450px;
    margin-bottom: 50px;
    margin-top: -150px;
  }
  .carousel-text {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
    margin-left: 35px;
    text-align: center;
    width: 350px;
  }
  .arrowMain {
    position: relative;
    margin-left: -50px;
    margin-top: -540px;
  }
  .buttonSingCourse {
    margin-left: 0px;
    margin-bottom: 30px;
  }
}
@media (min-width: 541px) and (max-width: 576px) {
  .position-absolute.top-50.start-50.translate-middle.text-center.titleMain {
    top: 12% !important;
    transform: translate(-50%, -10%) !important;
  }
  .carousel-title {
    font-size: 7.5vw;
    font-weight: 700;
    line-height: 1.2;
  }
  .carousel-text {
    font-size: 5.5vw;
    font-weight: 500;
    line-height: 1;
    width: 80vw;
    margin-left: 7vw;
  }
  .arrowMain {
    margin-left: -100px;
    margin-top: -20px;
  }
}
@media (min-width: 500px) and (max-width: 541px) {
  .position-absolute.top-50.start-50.translate-middle.text-center.titleMain {
    top: 12% !important;
    transform: translate(-50%, -10%) !important;
  }
  .carousel-title {
    font-size: 5.5vw;
    font-weight: 700;
    line-height: 1.2;
  }
  .carousel-text {
    font-size: 4.5vw;
    font-weight: 500;
    line-height: 1;
    width: 80vw;
    margin-left: 7vw;
    margin-top: -50px;
  }
  .arrowMain {
    margin-left: -100px;
    margin-top: -20px;
  }
}
@media (max-width: 500px) {
  .position-absolute.top-50.start-50.translate-middle.text-center.titleMain {
    top: 12% !important;
    transform: translate(-50%, -10%) !important;
  }
  .carousel-title {
    font-size: 7.5vw;
    font-weight: 700;
    line-height: 1.2;
  }
  .carousel-text {
    font-size: 5.5vw;
    font-weight: 500;
    line-height: 1;
    width: 80vw;
    margin-left: 7vw;
  }
  .arrowMain {
    margin-left: -100px;
    margin-top: -20px;
  }
  .buttonSingCourse {
    position: relative;
    top: -50%;
  }
}