@font-face {
  font-family: "Roboto";
  src: url("./styles/static/RobotoSlab-Regular.ttf");
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menu-animation-text {
  margin-right: 20px;
}
.dropdown-menu.show{
  background-color: #3CA3DC;
  z-index: 100;
}
.dropdown-item{
  background-color: black;

}
.menu-animation-text:hover {
  transform: scale(1.2);
  transition: 0.4s ease;
  z-index: 5;
}
#nav-dropdown-about,#nav-dropdown-location,#basic-nav-dropdown{
  color: black !important;
}
.dropdown-toggle::after{
  color: white;
}
.nav-link.active,
.nav-dropdown.active>.custom-color {
  text-decoration: underline;
}
.custom-dropdown-menu.show {
  display: block;
}
.onlineBtn {
  position: fixed;
  cursor: pointer;
  z-index: 99;
  width: 190px;
  height: 50px;
  border-radius: 80px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 2px;
  color: #eeeeed;
  background-color: #3CA3DC;
  border: 2px solid black;
  margin-left: 15px;
}
.onlineBtn.absolute {
  position: absolute; 
}
.onlineBtn:hover {
  transform: scale(1.1);
}
.onlineBtn .container {
  display: flex;
}
.onlineBtn svg {
  padding: 0 5px;
  opacity: 0;
  transition: all 1.2s ease;
  margin-top: -210px;
}
.containerSvgButton{
  margin-left: -20px;
}
.onlineBtn:hover svg {
  opacity: 1;
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(60, 163, 220, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(60, 163, 220, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(60, 163, 220, 0);
  }
}

.onlineBtn.pulse {
  animation: pulse 2s infinite;
}
.customColorNav:hover {
  color: white !important;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .NavDropDownItem {
    position: relative;
    left: 42%;
  }
}
@media (max-width: 768px) {
  .header {
    margin-bottom: 0;
    width: 100%;
  }
  .menu-animation-text:hover {
    transform: scale(1);
    z-index: 5;
  }
}
