.titlePrinciple {
    font-weight: 600;
    font-size: 2em;
}
.titlePrincipleBox {
    font-size: 1.5em;
    font-weight: 600;
}
.textPrincipleBox {
    font-size: 1.1em;
}
.iconsPrincipleBox {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #3CA3DC;
}
.iconsPrinciple {
    width: 50px;
    height: 50px;
}
