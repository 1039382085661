.textAreaForm {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #3CA3DC;
    border-radius: 10px;
    box-sizing: border-box;
    resize: none;
    white-space: nowrap;
    overflow: hidden;
}
.textAreaForm:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 5px 5px 5px #3CA3DC;
}
.textAreaForm:hover {
    border-color: #3CA3DC;
    box-shadow: 5px 5px 5px #3CA3DC;
}
.textAreaFormMessage {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #3CA3DC;
    border-radius: 10px;
    box-sizing: border-box;
}
.textAreaFormMessage:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 5px 5px 5px #3CA3DC;
}

.textAreaFormMessage:hover {
    border-color: #3CA3DC;
    box-shadow: 5px 5px 5px #3CA3DC;
}
.modalFormEmail{
    z-index: 1000;
}
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}
.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #3CA3DC;
    width: 90%;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.buttonSingCourse {
    margin-left: 200px;
    width: 200px;
    height: 60px;
    border-radius: 100px;
    background-color: #fff;
    color: black;
    font-weight: 700;
    transition: color .1s;
    border: 2px solid #3CA3DC;
}
.buttonSingCourse:active:before {
    background: #408eb8;
    transition: background 0s;
}
.buttonSingCourse:hover {
    background-color: #3CA3DC;
    border: 2px solid #fff;
}
.form-control {
    border-color: black;
    box-shadow: 2px 2px 5px #3187b6,
        -2px -2px 5px #2c749b;
}
#school1,#school2,#checkbox{
    border-color: black;
}
.titleModal {
    font-weight: bold;
    font-size: 30px;
}
.textModal{
    font-weight: bold;
    font-size: 19px;
}
.container input {
    display: none;
}
.container {
    display: block;
    position: relative;
    font-size: 20px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background-color: #2196F300;
    border-radius: 0.25em;
    transition: all 0.25s;
}
.container input:checked~.checkmark {
    background-color: #2196F3;
}
.checkmark:after {
    content: "";
    position: absolute;
    transform: rotate(0deg);
    border: 0.1em solid black;
    left: 0;
    top: 0;
    width: 1.05em;
    height: 1.05em;
    border-radius: 0.25em;
    transition: all 0.25s, border-width 0.1s;
}
.container input:checked~.checkmark:after {
    left: 0.50em;
    top: 0.35em;
    width: 0.25em;
    height: 0.5em;
    border-color: #fff0 white white #fff0;
    border-width: 0 0.15em 0.15em 0;
    border-radius: 0em;
    transform: rotate(45deg);
}
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 577px) and (max-width: 1025px) {
    .buttonSingCourse {
        margin-left: 50px;
    }
}
@media (max-width: 576px) {
    .buttonSingCourse {
        margin-left: auto;
    }
}