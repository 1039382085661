.marquee {
    width: 100%;
    height: 1.8rem;
    background-color: #95c6e1;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0; /* Отступы сверху и снизу одинаковые */
    position: relative;
}
  
.marquee p {
    display: inline-block;
    font-size: 18px;
    color: #000;
    padding-left: 100%; /* Начинаем с правой стороны */
    animation: marquee 50s linear infinite; /* Плавная, бесконечная анимация */
}
  
@keyframes marquee {
    0% {
        transform: translateX(100%); /* Начинает справа */
    }
    100% {
        transform: translateX(-100%); /* Уходит влево */
    }
}

@media (max-width: 768px) {
    .marquee p {
        animation: marquee 50s linear infinite; /* Сохраняем ту же длительность на мобильных устройствах */
        padding-left: 100%; /* Начинаем с правой стороны для мобильных устройств */
    }
}
