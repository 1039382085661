.container {
    text-align: center;
}
.home-text-firstSlide {
    font-size: 1.8vw;
}
.cardBG {
    background: #3CA3DC;
    box-shadow: 10px 10px 40px #3187b6,
        -10px -10px 40px #2c749b;
}
.card-title {
    font-size: 1.563em;
    font-weight: 600;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.251);
}
.card-text {
    font-size: 1.25em;
    font-weight: 600;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.251);
}
.boltTextHome {
    font-weight: 600;
}
.home-title-firstSlide {
    font-size: 2.8vw;
    font-weight: 600;
}
.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.socialMDMi,
.socialRo,
.socialMDSc,
.socialLe,
.socialMDVi {
    width: 220px;
    height: 220px;
}
.socialRo,
.socialLe:nth-child(even) {
    margin-top: 200px;
}
.environmentIMG {
    width: 210px;
    height: 210px;
    margin-left: -12px;
    border-radius: 50%;
    box-shadow: 10px 5px 5px gray;
    border: 1px solid black;
}
.environmentText {
    font-size: 1.4em;
    font-weight: 600;
    text-align: center;
    text-shadow: 10px 5px 5px gray;
}
.environmentTitle {
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
    text-shadow: 2px 2px 3px #3ca4dc9e;
}
.skillsTitle {
    font-size: 30px;
    font-weight: 600;
    text-shadow: 2px 2px 3px #3ca4dc9e;
    margin-top: 100px;
}
.parentSkills {
    width: 400px;
    height: 500px;
    perspective: 1000px;
}
.cardSkills {
    height: 100%;
    border-radius: 50px;
    background: linear-gradient(135deg, rgb(0, 217, 255) 0%, rgb(0, 170, 255) 100%);
    transition: all 0.5s ease-in-out;
    transform-style: preserve-3d;
    box-shadow: rgba(0, 4, 255, 0) 40px 50px 25px -40px, rgba(0, 21, 255, 0.2) 0px 25px 25px -5px;
}
.glassSkills {
    transform-style: preserve-3d;
    position: absolute;
    inset: 8px;
    border-radius: 55px;
    border-top-right-radius: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.349) 0%, rgba(255, 255, 255, 0.815) 100%);
    transform: translate3d(0px, 0px, 25px);
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    transition: all 0.5s ease-in-out;
}
.imageSTEAM {
    margin-top: 50px;
    margin-bottom: 30px;
}
.contentSkills {
    padding: 100px 60px 0px 30px;
    transform: translate3d(0, 0, 26px);
}
.contentSkills .titleSkills {
    display: block;
    color: black;
    font-weight: 600;
    font-size: 30px;
}
.contentSkills .textSkills {
    display: block;
    color: black;
    font-size: 19px;
    margin-top: 20px;
}
.parentSkills:hover .cardSkills {
    transform: rotate3d(1, 1, 0, 30deg);
    box-shadow: rgba(0, 123, 255, 0.3) 30px 50px 25px -40px, rgba(5, 47, 255, 0.2) 0px 25px 30px 0px;
}
.parentSkills1 {
    width: 400px;
    height: 200px;
    perspective: 1000px;
}
.cardSkills1 {
    height: 100%;
    border-radius: 50px;
    background: linear-gradient(135deg, rgb(0, 217, 255) 0%, rgb(0, 170, 255) 100%);
    transition: all 0.5s ease-in-out;
    transform-style: preserve-3d;
    box-shadow: rgba(0, 4, 255, 0) 40px 50px 25px -40px, rgba(0, 21, 255, 0.2) 0px 25px 25px -5px;
}
.glassSkills1 {
    transform-style: preserve-3d;
    position: absolute;
    inset: 8px;
    border-radius: 55px;
    border-top-right-radius: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.349) 0%, rgba(255, 255, 255, 0.815) 100%);
    transform: translate3d(0px, 0px, 25px);
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    transition: all 0.5s ease-in-out;
}
.contentSkills1 {
    padding: 100px 60px 0px 30px;
    transform: translate3d(0, 0, 26px);
}
.contentSkills1 .titleSkills1 {
    display: block;
    color: black;
    font-weight: 600;
    font-size: 30px;
}
.contentSkills1 .textSkills1 {
    display: block;
    color: black;
    font-size: 19px;
    margin-top: -40px;
}
.parentSkills1:hover .cardSkills1 {
    transform: rotate3d(1, 1, 0, 30deg);
    box-shadow: rgba(0, 123, 255, 0.3) 30px 50px 25px -40px, rgba(5, 47, 255, 0.2) 0px 25px 30px 0px;
}
.partnersHome {
    width: 200px;
    height: 150px;
}
.partnersTitle {
    font-size: 30px;
    font-weight: 600;
    text-shadow: 2px 2px 3px #3ca4dc9e;
}
.logoWithTextHome{
    display: flex;
    text-align: center;
    justify-content: center; 
}
.logoBeforeTextHome{
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .socialMDMi,
    .socialRo,
    .socialMDSc,
    .socialLe,
    .socialMDVi {
        width: 170px;
        height: 170px;
        margin-bottom: 80px;
    }
    .environmentTitle {
        font-size: 18px;
    }
    .environmentIMG {
        width: 200px;
        height: 200px;
    }
    .environmentText {
        font-size: 20px;
        margin-left: 30px;
    }
    .environmentTitle {
        font-size: 25px;
    }
    .cardSkills {
        width: 350px;
        height: 520px;
        margin-left: -20px;
    }
    .cardSkills1 {
        width: 350px;
        height: 200px;
        margin-left: -20px;
    }
}
@media (min-width: 1023px) and (max-width: 1025px) {
    .socialMDMi,
    .socialRo,
    .socialMDSc,
    .socialLe,
    .socialMDVi {
        width: 170px;
        height: 170px;
        margin-bottom: 80px;
    }
    .environmentTitle {
        font-size: 18px;
    }
    .environmentIMG {
        width: 200px;
        height: 200px;
    }
    .environmentText {
        font-size: 20px;
        margin-left: 30px;
    }
    .environmentTitle {
        font-size: 25px;
    }
    .cardSkills {
        width: 350px;
        height: 520px;
        margin-left: -20px;
    }
    .cardSkills1 {
        width: 350px;
        height: 200px;
        margin-left: -20px;
    }
}
@media (min-width: 736px) and (max-width: 1023px) {
    .parentSkills {
        width: 0px;
        height: 480px;
        perspective: 1000px;
    }
    .contentSkills .titleSkills {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 25px;
    }
    .contentSkills .textSkills {
        display: block;
        color: black;
        font-size: 16px;
        margin-top: 20px;
    }
    .socialLe img {
        margin-top: -200px;
    }
    .socialMDVi {
        margin-left: 220px;
    }
    .cardSkills {
        width: 300px;
        height: 500px;
        margin-left: -35px;
    }
    .totalCardSkills {
        padding-right: 200px;
    }
    .cardSkills1 {
        width: 350px;
        height: 200px;
        margin-left: -55px;
        margin-right: 20px;
    }
    .totalCardSkills1 {
        padding-right: 200px;
    }
    .parentSkills1 {
        width: 0px;
        height: 180px;
        perspective: 1000px;
    }
    .contentSkills1 .textSkills1 {
        font-size: 18px;
    }
    .skillsTitle {
        margin-top: -100px;
    }
}
@media (min-width: 895px) and (max-width: 933px) {
    .cardSkills1 {
        width: 350px;
        height: 200px;
        margin-left: -30px;
    }
    .cardSkills {
        width: 350px;
        height: 450px;
        margin-left: -40px;
    }
    .socialRo:nth-child(even) {
        margin-bottom: 30px;
        margin-left: 50px;
        margin-right: 50px;
    }
    .socialMDVi {
        margin-left: 300px;
    }
    .socialLe {
        margin-left: 20px;
    }
    .skillsTitle {
        margin-top: -100px;
    }
}
@media (min-width: 665px) and (max-width: 721px) {
    .parentSkills {
        width: 0px;
        height: 480px;
        perspective: 1000px;
    }
    .contentSkills .titleSkills {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 25px;
    }
    .contentSkills .textSkills {
        display: block;
        color: black;
        font-size: 16px;
        margin-top: 20px;
    }
    .socialLe img {
        margin-top: -200px;
    }
    .socialMDVi {
        margin-left: 220px;
    }
    .cardSkills {
        width: 300px;
        height: 500px;
        margin-left: -55px;
    }
    .totalCardSkills {
        padding-right: 200px;
    }
    .cardSkills1 {
        width: 300px;
        height: 200px;
        margin-left: -50px;
    }
    .totalCardSkills1 {
        padding-right: 200px;
    }
    .parentSkills1 {
        width: 0px;
        height: 180px;
        perspective: 1000px;
    }
    .contentSkills1 .textSkills1 {
        font-size: 18px;
    }
    .skillsTitle {
        margin-top: -100px;
    }
}
@media (min-width: 640px) and (max-width: 660px) {
    .parentSkills {
        width: 0px;
        height: 480px;
        perspective: 1000px;
    }
    .contentSkills .titleSkills {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 25px;
    }
    .contentSkills .textSkills {
        display: block;
        color: black;
        font-size: 16px;
        margin-top: 20px;
    }
    .socialLe img {
        margin-top: -200px;
    }
    .socialMDVi {
        margin-left: 20px;
        margin-top: -200px;
    }
    .socialRo {
        margin-top: 0px;
        margin-bottom: 80px;
    }
    .socialMDSc {
        margin-top: 10px;
        margin-bottom: 80px;
    }
    .socialLe {
        margin-top: 0px;
        margin-bottom: 80px;
    }
    .cardSkills {
        width: 300px;
        height: 500px;
        margin-left: -55px;
    }
    .totalCardSkills {
        padding-right: 200px;
    }
    .cardSkills1 {
        width: 300px;
        height: 200px;
        margin-left: -50px;
    }
    .totalCardSkills1 {
        padding-right: 200px;
    }
    .parentSkills1 {
        width: 0px;
        height: 180px;
        perspective: 1000px;
    }
    .contentSkills1 .textSkills1 {
        font-size: 18px;
    }
    .skillsTitle {
        margin-top: 20px;
    }
}
@media (min-width: 376px) and (max-width: 576px) {
    .socialRo,
    .socialLe:nth-child(even) {
        margin-top: 70px;
    }
    .socialMDSc,
    .socialMDVi {
        margin-top: 70px;
    }
    .environmentText {
        font-size: 4.7vw;
        font-weight: 600;
        text-shadow: 10px 5px 5px gray;
        margin-top: 10px;
    }
    .environmentTitle {
        font-size: 4.8vw;
        font-weight: 600;
        text-shadow: 2px 2px 3px #3ca4dc9e;
    }
    .cardSkills {
        width: 350px;
        height: 520px;
        margin-left: 10px;
    }
    .parentSkills {
        width: 300px;
        height: 480px;
        perspective: 1000px;
    }
    .cardSkills1 {
        width: 350px;
        height: 200px;
        margin-left: 18px;
    }
    .parentSkills1 {
        width: 0px;
        height: 200px;
        perspective: 1000px;
    }
}
@media (min-width: 539px) and (max-width: 541px) {
    .cardSkills1 {
        width: 350px;
        height: 200px;
        margin-left: 80px;
    }
    .cardSkills {
        width: 350px;
        height: 520px;
        margin-left: 70px;
    }
    .socialRo:nth-child(even) {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 50px;
    }
    .socialMDVi {
        margin-left: 30px;
    }
    .socialMDMi {
        margin-left: 50px;
        margin-bottom: 20px;
    }
    .socialLe {
        margin-left: 20px;
    }
}
@media (min-width: 375px) and (max-width: 376px) {
    .socialRo,
    .socialLe:nth-child(even) {
        margin-top: 70px;
    }
    .socialMDSc,
    .socialMDVi {
        margin-top: 70px;
    }
    .environmentText {
        font-size: 4.7vw;
        font-weight: 600;
        text-shadow: 10px 5px 5px gray;
        margin-top: 10px;
    }
    .environmentTitle {
        font-size: 4.8vw;
        font-weight: 600;
        text-shadow: 2px 2px 3px #3ca4dc9e;
    }
    .cardSkills {
        width: 330px;
        height: 550px;
        margin-left: 0px;
    }
    .parentSkills {
        width: 300px;
        height: 550px;
        perspective: 1000px;
    }
    .parentSkills1 {
        width: 0px;
        height: 200px;
        perspective: 1000px;
    }
    .cardSkills1 {
        width: 330px;
        height: 200px;
        margin-left: 8px;
    }
    .contentSkills1 .titleSkills1 {
        font-size: 28px;
    }
    .contentSkills1 .textSkills1 {
        font-size: 18px;
    }
}
@media (min-width: 321px) and (max-width: 361px) {
    .socialRo,
    .socialLe:nth-child(even) {
        margin-top: 70px;
    }
    .socialMDSc,
    .socialMDVi {
        margin-top: 70px;
    }
    .environmentText {
        font-size: 4.7vw;
        font-weight: 600;
        text-shadow: 10px 5px 5px gray;
        margin-top: 10px;
    }
    .environmentTitle {
        font-size: 4.8vw;
        font-weight: 600;
        text-shadow: 2px 2px 3px #3ca4dc9e;
    }
    .parentSkills {
        width: 250px;
        height: 400px;
        margin-left: -10px;
        perspective: 1000px;
    }
    .parentSkills1 {
        width: 0px;
        height: 200px;
        margin-left: -4px;
        perspective: 1000px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .cardSkills1 {
        width: 250px;
        height: 280px;
        margin-left: 50px;
    }
    .cardSkills {
        width: 270px;
        height: 400px;
        margin-left: 30px;
    }
    .contentSkills .titleSkills {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 22px;
        margin-top: -40px;
    }
    .contentSkills .textSkills {
        display: block;
        color: black;
        font-size: 14px;
        margin-top: 20px;
    }
    .logoBeforeTextHome{
        width: 30px;
        height: 30px;
        margin-top: -38px;
    }
}
@media (min-width: 281px) and (max-width: 321px) {
    .socialRo,
    .socialLe:nth-child(even) {
        margin-top: 70px;
    }
    .socialMDSc,
    .socialMDVi {
        margin-top: 70px;
    }
    .environmentText {
        font-size: 4.7vw;
        font-weight: 600;
        text-shadow: 10px 5px 5px gray;
        margin-top: 10px;
    }
    .environmentTitle {
        font-size: 4.8vw;
        font-weight: 600;
        text-shadow: 2px 2px 3px #3ca4dc9e;
    }
    .parentSkills {
        width: 250px;
        height: 400px;
        margin-left: -10px;
        perspective: 1000px;
    }
    .parentSkills1 {
        width: 0px;
        height: 200px;
        margin-left: -4px;
        perspective: 1000px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .cardSkills1 {
        width: 250px;
        height: 280px;
        margin-left: 25px;
    }
    .cardSkills {
        width: 250px;
        height: 400px;
        margin-left: 22px;
    }
    .contentSkills .titleSkills {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 22px;
        margin-top: -40px;
    }
    .contentSkills .textSkills {
        display: block;
        color: black;
        font-size: 14px;
        margin-top: 20px;
    }
    .logoBeforeTextHome{
        width: 30px;
        height: 30px;
        margin-top: -38px;
    }
}
@media (max-width: 281px) {
    .socialRo,
    .socialLe:nth-child(even) {
        margin-top: 70px;
    }
    .socialMDSc,
    .socialMDVi {
        margin-top: 70px;
    }
    .environmentText {
        font-size: 1.2rem;
        font-weight: 600;
        text-shadow: 10px 5px 5px gray;
        margin-top: 10px;
    }
    .environmentTitle {
        font-size: 4.8vw;
        font-weight: 600;
        text-shadow: 2px 2px 3px #3ca4dc9e;
    }
    .parentSkills {
        width: 250px;
        height: 400px;
        margin-left: -10px;
        perspective: 1000px;
    }
    .parentSkills1 {
        width: 0px;
        height: 200px;
        margin-left: -4px;
        perspective: 1000px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .cardSkills1 {
        width: 250px;
        height: 280px;
        margin-left: 10px;
    }
    .contentSkills .titleSkills {
        display: block;
        color: black;
        font-weight: 600;
        font-size: 22px;
        margin-top: -40px;
    }
    .contentSkills .textSkills {
        display: block;
        color: black;
        font-size: 14px;
        margin-top: 20px;
    }
    .logoBeforeTextHome{
        width: 30px;
        height: 30px;
        margin-top: -38px;
    }
}