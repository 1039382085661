* {
    font-family: "Roboto", sans-serif;
}
.legal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    max-width: 1200px;
    margin: auto;
}
.titleLegal {
    font-size: 40px;
    font-weight: 700;
    width: 100%;
    text-align: center;
}
.titleLegaltitle {
    font-size: 25px;
    font-weight: 500;
    color: #3CA3DC;
}
.textLegal {
    font-size: 17px;
    font-weight: 500;
}
.FOP {
    font-size: 17px;
    font-weight: 700;
}
.bgtext {
    color: #3CA3DC;
    font-weight: 900;
}
@media (max-width: 1180px) {
    .legal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;
        max-width: 900px;
        margin: auto;
    }
    .titleLegal {
        font-size: 35px;
        font-weight: 700;   
    }
    .titleLegaltitle {
        font-size: 25px;
        font-weight: 500;
        color: #3CA3DC;
    }
    .textLegal {
        font-size: 22px;
        font-weight: 500;
    }
    .FOP {
        font-size: 18px;
        font-weight: 700;
    }
    .bgtext {
        color: #3CA3DC;
        font-weight: 900;
    }
}
@media (min-width: 650px) and (max-width: 1000px) {
    .legal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;
        max-width: 600px;
        margin: auto;
    }
    .titleLegal {
        font-size: 22px;
        font-weight: 700;
        
    }
    .titleLegaltitle {
        font-size: 16px;
        font-weight: 500;
        color: #3CA3DC;
    }
    .textLegal {
        font-size: 10px;
        font-weight: 500;
    }
    .FOP {
        font-size: 10px;
        font-weight: 700;
    }
    .bgtext {
        color: #3CA3DC;
        font-weight: 900;
    }
}
@media (min-width: 711px) and (max-width: 820px) {
    .legal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;
        max-width: 700px;
        margin: auto;
    }
    .titleLegal {
        font-size: 35px;
        font-weight: 700; 
    }
    .titleLegaltitle {
        font-size: 25px;
        font-weight: 500;
        color: #3CA3DC;
    }
    .textLegal {
        font-size: 18px;
        font-weight: 500;
    }
    .FOP {
        font-size: 18px;
        font-weight: 700;
    }
    .bgtext {
        color: #3CA3DC;
        font-weight: 900;
    }
}
@media (max-width: 710px) {
    .legal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;
        max-width: 350px;
        margin: auto;
    }
    .titleLegal {
        font-size: 25px;
        font-weight: 700;
        
    }
    .titleLegaltitle {
        font-size: 18px;
        font-weight: 500;
        color: #3CA3DC;
    }
    .textLegal {
        font-size: 16px;
        font-weight: 500;
    }
    .FOP {
        font-size: 12px;
        font-weight: 700;
    }
    .bgtext {
        color: #3CA3DC;
        font-weight: 900;
    }
}
@media (max-width: 400px) {
    .legal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;
        max-width: 310px;
        margin: auto;
    }
    .titleLegal {
        font-size: 22px;
        font-weight: 700;  
    }
    .titleLegaltitle {
        font-size: 16px;
        font-weight: 500;
        color: #3CA3DC;
    }
    .textLegal {
        font-size: 10px;
        font-weight: 500;
    }
    .FOP {
        font-size: 10px;
        font-weight: 700;
    }
    .bgtext {
        color: #3CA3DC;
        font-weight: 900;
    }
}
@media (max-width: 300px) {
    .legal-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;
        max-width: 250px;
        margin: auto;
    }
    .titleLegal {
        font-size: 20px;
        font-weight: 700;  
    }
    .titleLegaltitle {
        font-size: 13px;
        font-weight: 500;
        color: #3CA3DC;
    }
    .textLegal {
        font-size: 8px;
        font-weight: 500;
    }
    .FOP {
        font-size: 8px;
        font-weight: 700;
    }
    .bgtext {
        color: #3CA3DC;
        font-weight: 900;
    }
}