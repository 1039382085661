.fade-in-text {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-in-text.show {
    opacity: 1;
    transform: translateY(0);
}
.textBoldQuestions{
    font-weight: bold;
}
.textQuestions{
    font-size: 18px;
    width: 1200px;
}
.titleQuestions{
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}
.elementQuestions1{
    width: 80px;
    position: absolute;
    margin-right: 50px;
    margin-top: -90px;
    right: 0;
}
.elementQuestions2{
    width: 70px;
    position: absolute;
    margin-left: 50px;
    margin-top: -390px;
    transform: rotate(-20deg);
    left: 0;
}
.elementQuestions3{
    width: 150px;
    position: absolute;
    margin-top: -700px;
    right: 0;
}
.elementQuestions4{
    width: 50px;
    position: absolute;
    margin-left: 50px;
    margin-top: -1000px;
    left: 0;
}
.linkQuestionsA{
    overflow-wrap: break-word;
    word-break: break-all;
}
@media (min-width: 100px) and (max-width:1400px) {
    .elementQuestions1,
    .elementQuestions2,
    .elementQuestions3,
    .elementQuestions4{
        display: none;
    }
}
@media (min-width: 100px) and (max-width:1200px) {
    .textQuestions{
        font-size: 18px;
        width: auto;
    }
}

