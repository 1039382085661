.containerSchedule{
    margin-bottom: 20px;
}
.imagesScheduleKinder{
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}
.imgScheduleKinder1,.imgScheduleKinder2,.imgScheduleKinder3{
    width: 25rem;
    height: 15.5rem;
    border-radius: 30px;
    border: 1px solid #3CA3DC;
}
@media (min-width: 1000px) and (max-width: 1400px) {
    .imgScheduleKinder1,.imgScheduleKinder2,.imgScheduleKinder3{
        width: 20rem;
        height: 15rem;
    }
}
@media (min-width: 700px) and (max-width: 1000px) {
    .imgScheduleKinder1{
        display: none;
    }
    .imgScheduleKinder1,.imgScheduleKinder2,.imgScheduleKinder3{
        width: 20rem;
        height: 15rem;
    }
}
@media (min-width: 400px) and (max-width: 700px) {
    .imgScheduleKinder1,.imgScheduleKinder2{
        display: none;
    }
}
@media (min-width: 321px) and (max-width: 400px) {
    .imgScheduleKinder1,.imgScheduleKinder2,.imgScheduleKinder3{
        width: 20rem;
        height: 15.5rem;
    }
    .imgScheduleKinder1,.imgScheduleKinder2{
        display: none;
    }
}
@media (min-width: 1px) and (max-width: 321px) {
    .imgScheduleKinder1,.imgScheduleKinder2,.imgScheduleKinder3{
        width: 15rem;
        height: 12rem;
    }
    .imgScheduleKinder1,.imgScheduleKinder2{
        display: none;
    }
}