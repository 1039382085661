.logoTextContainerPage{
    padding-top: 2rem;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: -2rem;
}

.nameCoursePage{
    font-size: 1.6rem;
    font-weight: bold;
}

.textInfoCoursesPage {
    margin-top: 100px;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}

.coursesBoxPage {
    margin-bottom: 50px;
}
.successTitleCourses {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

.img2CoursesPage img {
    margin-top: 140px;
    width: 500px;
    height: 400px;
    margin-bottom: 80px;
    border-radius: 10%;
    box-shadow: 10px 5px 5px gray;
    object-fit: cover;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .coursesBoxPage {
      flex-direction: column; 
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .img2CoursesPage, 
    .textInfoCourses {
      margin-top: 2rem; 
      margin-bottom: -5rem;
    }
    .nameCoursePage{
        font-size: 1.6rem;
        font-weight: bold;
    }
    .img2CoursesPage img {
      margin-bottom: 2rem; 
      margin-top: 1rem;
    }
    .img2CoursesPage  {
        width: 100%;
      }
  
    .textInfoCoursesPage {
      text-align: center; 
      width: 90%;
    }
  }
  

@media (max-width: 767px) {
    .img2CoursesPage img {
        margin-top: 4rem;
        margin-bottom: -3rem;
        width: 400px;
        height: 250px;
    }
}

@media (max-width: 281px) {
    .img2CoursesPage img {
        margin-top: 2rem;
        margin-bottom: -3rem;
        width: 400px;
        height: 250px;
    }
}