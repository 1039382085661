.container {
    max-width: 1400px;
    box-sizing: border-box;
}
.titleNameAbout{
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}
.textInfoAbout{
    margin-top: 50px;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}
.boldtextAbout{
    font-weight: 600;
}
.img1About img,
.img2About img,
.img3About img{
    width: 500px;
    border: 1px solid #3CA3DC;
    border-radius: 20px;
    box-shadow: 10px 5px 5px rgb(209, 209, 209);;
}
.textAboutDown{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.img2About2 img{
    object-fit: cover;
    display: none;
    border: 1px solid #3CA3DC;
    border-radius: 20px;
    box-shadow: 10px 5px 5px rgb(209, 209, 209);;
}
.img2About2{
    width: 500px;
}
.elementAbout1{
    width: 80px;
    position: absolute;
    margin-left: 50px;
    margin-top: -950px;
    transform: rotate(30deg);
    left: 0;
}
.elementAbout2{
    width: 70px;
    position: absolute;
    margin-right: 50px;
    margin-top: -690px;
    transform: rotate(-20deg);
    right: 0;
}
.elementAbout3{
    width: 100px;
    position: absolute;
    margin-left: 50px;
    margin-top: -400px;
    left: 0;
}
.elementAbout4{
    width: 150px;
    position: absolute;
    margin-right: 10px;
    margin-top: -200px;
    right: 0;
}
@media (min-width: 100px) and (max-width:1200px) {
    .elementAbout1,
    .elementAbout2,
    .elementAbout3,
    .elementAbout4{
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .textInfoAbout {
        width: 100%;
        margin: 0;
    }
    .img1About,
    .img2About,
    .img2About2,
    .img3About {
        width: 500px;
    }
}
@media (min-width: 577px) and (max-width: 1200px) {
    .textInfoAbout{
        margin-top: 50px;
        font-size: 20px;
    }
    .elementsBG1,.elementsBG2,.elementsBG3{
        display: none;
    }
    .img2About2 img{
        display: block;
    }
    .img2About img{
        display: none;
    }
}
@media (max-width: 576px) {
    .img2About{
        display: none;
    }
    .elementsBG1,.elementsBG2,.elementsBG3{
        display: none;
    }
    .img2About2 img{
        display: block;
    }
    .textAboutDown{
        margin-bottom: 5rem;
    }
}
