.BGContacts {
    background-image: url('../assets/BGContacts3.png');
    overflow: scale-down;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}
.contactsTitle {
    font-size: 2.1875rem;
    font-weight: 600;
    color: black;
}
.contactsText {
    font-size: 1.25rem;
    font-weight: 600;
    color: black;
}
.contactsText a{
    color: inherit;
    text-decoration: none;
}
.socialsRivneIcons,
.socialsKyivIcons {
    display: flex;
    justify-content: space-between;
    margin: 0 140px;
    filter: drop-shadow(1px 1px 1px #3CA3DC);
}
.svgContainer {
    flex: 1;
    margin: 0 -1px;
}
.row1Container {
    margin-left: 4rem;
}
@media (min-width: 1024px) and (max-width: 1280px) {
    .colRightContacts {
        margin-left: 100px;
        width: 500px;
    }
    .colLeftContacts{
        width: 500px;
    }
    .contactsText {
        font-size: 1.3rem;
    }
    .socialsKyivIcons,
    .socialsRivneIcons {
        position: relative;
        margin: 0 0px 50px 70px;
        margin-left: 0px;
    }
    .BGContacts {
        background-image: url('../assets/phoneBG1.jpg');
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .titleMain {
        margin-top: 350px;
    }
    
    .contactsText {
        margin-left: -40px;
    }
    .colLeftContacts{
        width: 400px;
        margin-left: 40px;
    }
    .colRightContacts {
        margin-left: 70px;
        width: 340px;
    }
    .socialsKyivIcons,
    .socialsRivneIcons {
        position: relative;
        margin: 0 0px 50px 70px;
        margin-left: -50px;
    }
    .BGContacts {
        background-image: url('../assets/phoneBG1.jpg');
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .titleMain {
        margin-top: 350px;
    }
    .socialsKyivIcons,
    .socialsRivneIcons {
        margin-bottom: 50px;
    }
    .BGContacts {
        background-image: url('../assets/phoneBG1.jpg');
    }
}
@media (min-width: 376px) and (max-width: 576px) {
    .contactsText {
        font-size: 1.1rem;
    }
    .BGContacts {
        background-image: url('../assets/phoneBG1.jpg');
    }
    .socialsKyivIcons,
    .socialsRivneIcons {
        position: relative;
        margin: 0 120px 50px 110px;
    }
}
@media  (max-width: 376px) {
    .contactsText {
        font-size: 0.890rem;
    }
    .socialsKyivIcons,
    .socialsRivneIcons {
        position: relative;
        left: -15%;
        margin: 0 9px 50px 90px;
    }
    .BGContacts {
        background-image: url('../assets/phoneBG1.jpg');
    }
}