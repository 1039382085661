.titleNameCourses {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}

.textInfoCourses {
    margin-top: 100px;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}

.img2Courses img {
    border-radius: 10%;
}

.img2Courses img {
    margin-top: 80px;
    width: 480px;
    height: 380px;
    margin-bottom: 80px;
    box-shadow: 10px 5px 5px gray;
    object-fit: cover;
}

.coursesBox {
    border-bottom: 1px solid black;
    margin-bottom: 50px;
}

.nameCourse {
    font-size: 20px;
    font-weight: 600;
}

.nameCourse1 {
    font-size: 25px;
    font-weight: 600;
}

.successTitleCourses {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.logoBeforeText {
    width: 35px;
    height: 35px;
    animation: rotate 3s linear infinite;
}

.logoTextContainer {
    display: flex;
    text-align: center;
    justify-content: center;
}

.LinkForCoursePageText {
    color: #3CA3DC;
}

.LinkForCoursePageText:hover {
    color: black;
}

.titleModalNMT{
    font-size: 1.6rem !important;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .coursesBox {
      flex-direction: column; 
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .img2Courses, 
    .textInfoCourses {
      margin-top: 2rem; 
    }
    .nameCourse{
        font-size: 1.6rem;
        font-weight: bold;
    }
    .img2Courses img {
      margin-bottom: 2rem; 
      margin-top: 1rem;
    }
    .img2Courses  {
        width: 100%;
      }
  
    .textInfoCourses {
      text-align: center; 
      width: 90%;
    }
  }
@media (max-width: 756px) {
    .img2Courses img {
        margin-top: 2rem;
        margin-bottom: -3rem;
        width: 400px;
        height: 250px;
    }
}

@media (max-width: 281px) {
    .img2Courses img {
        margin-top: 2rem;
        margin-bottom: -3rem;
        width: 400px;
        height: 250px;
    }
}