.map-section .gmap-frame #gmap_canvas{
    width: 80%;
    border-radius: 50px;
    border: 2px solid #3CA3DC;
}
.titleKyivLoc{
    font-size: 35px;
    font-weight: 600;
}
.elementKyivLoc3{
    width: 150px;
    position: absolute;
    margin-right: 50px;
    margin-top: -200px;
    right: 0;
}
.elementKyivLoc4{
    width: 50px;
    position: absolute;
    margin-left: 70px;
    margin-top: -600px;
    left: 0;
}
@media (min-width: 100px) and (max-width:1200px) {
    .elementKyivLoc3,
    .elementKyivLoc4{
        display: none;
    }
}