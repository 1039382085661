/* Добавляем стили для модального окна */
.custom-modal .modal-content {
    background-color: white; /* Цвет фона модального окна */
    color: black; /* Цвет текста модального окна */
    z-index: 1051; /* Повышаем z-index модального окна */
    box-shadow: 20px 20px 20px  rgba(0,0,0,0.5);
  }

/* Пример кастомных стилей для .modal-dialog */
.custom-modal .modal-dialog {
    z-index: 1051; /* Повышаем z-index модального окна */
    position: relative; /* Относительное позиционирование для правильного наложения */
  }
    