.titleKinder{
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}
.textKinder{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}
.boldTextKinder{
    font-weight: 600;
}
.imgKinder{
    width: 700px;
    height: 400px;
    border-radius: 20px;
    border: 1px solid #3CA3DC;
}
.elementKinder1{
    width: 70px;
    position: absolute;
    margin-right: 100px;
    margin-top: -800px;
    transform: rotate(30deg);
    right: 0;
}
.elementKinder4{
    width: 150px;
    position: absolute;
    margin-left: 50px;
    margin-top: -590px;
    transform: rotate(-40deg);
    left: 0;
}
.elementKinder3{
    width: 80px;
    position: absolute;
    margin-right: 50px;
    margin-top: -150px;
    right: 0;  
}
.elementKinder2{
    width: 60px;
    position: absolute;
    margin-left: 50px;
    margin-top: -200px;
    left: 0;
}
@media (min-width: 100px) and (max-width:1200px) {
    .elementKinder1,
    .elementKinder2,
    .elementKinder3,
    .elementKinder4{
        display: none;
    }
}
@media (max-width: 1300px) {
    .imgKinder{
        width: 700px;
        height: 400px;
    }
}
@media (max-width: 768px) {
    .imgKinder{
        width: 500px;
        height: 300px;
    }
}
@media (max-width: 568px) {
    .imgKinder{
        width: 350px;
        height: 200px;
    }
}
@media (max-width: 281px) {
    .imgKinder{
        width: 250px;
        height: 150px;
    }
    .titleKinder{
        font-size: 25px;
        font-weight: 600;
        text-align: center;
    }
    .textKinder{
        font-size: 15px;
        font-weight: 400;
        text-align: center;
    }
}
