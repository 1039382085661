.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    width: 50px;
    height: 50px;
    background-color: #3CA3DC;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    transition: background-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: scale(0.8);
    border: none;
}

.scroll-to-top.show {
    opacity: 0.7; /* Плавное появление */
    transform: scale(1); /* Плавное увеличение до исходного размера */
    background-color: #3CA3DC;
  }

.scroll-to-top:hover {
    opacity: 1;
    /* Полностью видимая при наведении */
    background-color: #3CA3DC;
    /* Более темный синий цвет при наведении */
}

.scroll-to-top:active {
    background-color: #3CA3DC;
    /* Цвет при клике (активации) */
}

@media (max-width: 768px) {
    .scroll-to-top {
        bottom: 15px;
        right: 15px;
        font-size: 2rem;
        /* Меньший размер иконки на мобильных */
    }
}

@media (max-width: 480px) {
    .scroll-to-top {
        bottom: 10px;
        right: 10px;
        font-size: 2rem;
        /* Еще меньший размер иконки на совсем маленьких экранах */
    }
}

/* Отключаем кнопку вблизи футера */
footer {
    position: relative;
    z-index: 1;
}

.scroll-to-top {
    z-index: 2;
    /* Кнопка будет поверх, но не будет накладываться на футер */
}