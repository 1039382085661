.bg-body-primary {
    background-color: #3CA3DC;
}
.footer-text,.footer-email-text {
    color: black;
    font-size: 1.2rem;
    font-weight: 900;
    text-decoration: none;
}
.footer-text a, .footer-email-text a{
    color: inherit; 
    text-decoration: none;
}
.footer-text:hover,.footer-email-text:hover {
    font-size: 1.2rem;
    color: white;
    transition: color 0.3s ease-in-out;
}
@media (min-width: 767px) and (max-width: 1024px) {
    .footer-text,.footer-email-text {
        font-size: 1rem;
    }
    .footer-text:hover,.footer-email-text:hover {
        transition: color 0.3s ease-in-out;
    }
}
@media (min-width: 360px) and (max-width: 767px) {
    .footer-text,.footer-email-text {
        font-size: 1.2rem;
        overflow-wrap: break-word;
        word-break: break-all;
    }
    .footer-text:hover,.footer-email-text:hover {
        transition: color 0.3s ease-in-out;
    }
}
@media (max-width: 360px) {
    .footer-text{
        font-size: 1rem;
        overflow-wrap: break-word;
        word-break: break-all;
    }
    .footer-email-text {
        font-size: 0.9rem;
        overflow-wrap: break-word;
        word-break: break-all;
    }
    .footer-text:hover,.footer-email-text:hover {
        transition: color 0.3s ease-in-out;
    }
}