.titleLoyalty{
    font-size: 35px;
    font-weight: 600;
}
.nameLoyalty{
    font-size: 25px;
    font-weight: 600;
}
.textLoyalty{
    text-align: left;
    font-size: 21px;
    margin-top: -30px;
}
.textLoyalty1{
    text-align: left;
    font-size: 21px;
}
.boldFontLoyalty{
    font-weight: 600;
}
.imgLoyalty img{
    width: 600px;
    height: 400px;
}
.elementLoyalty3{
    width: 90px;
    position: absolute;
    margin-right: 50px;
    margin-top: -200px;
    right: 0;  
}
.elementLoyalty4{
    width: 70px;
    position: absolute;
    margin-left: 50px;
    margin-top: -480px;
    transform: rotate(20deg);
    left: 0;
}
@media (min-width: 100px) and (max-width:1200px) {
    .elementLoyalty4,
    .elementLoyalty3{
        display: none;
    }
}
@media (min-width: 100px) and (max-width:281px) {
    .textLoyalty{
        text-align: left;
        font-size: 18px;
    }
}